.LoaderContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
  .LoaderContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .LogoContainer {
    height: 150px;
    width: 150px;
    
  }
  .loaderLogo {
    max-width: 100%;
  }
  
  .loaderLogo2 {
    max-width: 100%;
  }
  
  .nuOpLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }
  .nuOpLoader:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid  #00A15F;
    border-color: #00A15F transparent #00A15F transparent;
    -webkit-animation: nuOpLoader .8s linear infinite;
            animation: nuOpLoader .8s linear infinite;
  }
  @-webkit-keyframes nuOpLoader {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  @keyframes nuOpLoader {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  /* Loader2 styles  */
  
  .Logo2Container {
    height: 350px;
    width: 350px;
  }
  
  .nuOpLoader2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 230px;
    
  }
  .nuOpLoader2:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid  #00A15F;
    border-color: #00A15F transparent #00A15F transparent;
    -webkit-animation: nuOpLoader .4s linear infinite;
            animation: nuOpLoader .4s linear infinite;
  }
  @-webkit-keyframes nuOpLoader2 {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  @keyframes nuOpLoader2 {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  


  
  
  
@font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: url(/static/media/Roboto-Medium.68ea4734.ttf); /* IE9 Compat Modes */
}

/* montserrat-regular - latin */
@font-face {
    font-display: fallback;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(/static/media/montserrat-v14-latin-regular.5cc74ef8.eot); /* IE9 Compat Modes */
    src: local("Montserrat Regular"),
        
            url(/static/media/montserrat-v14-latin-regular.5cc74ef8.eot?#iefix)
            format("embedded-opentype"),
        
            url(/static/media/montserrat-v14-latin-regular.bc3aa95d.woff2)
            format("woff2"),
        
            url(/static/media/montserrat-v14-latin-regular.8102c483.woff)
            format("woff"),
        
            url(/static/media/montserrat-v14-latin-regular.6a9e85ac.ttf)
            format("truetype"),
        
            url(/static/media/montserrat-v14-latin-regular.f3fef7e5.svg#Montserrat)
            format("svg"); /* Legacy iOS */
}

@font-face {
    font-display: fallback;
    font-family: "Montserrat Bold";
    font-style: bold;
    font-weight: bold;
    font-display: auto;
    src: url(/static/media/Montserrat-Bold.9c71d42b.otf); /* IE9 Compat Modes */
    src: local("Montserrat Bold"); /* local('Montserrat-Regular'), */
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI Regular";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: local("Inter UI Regular"),
        url(/static/media/Inter-UI-Regular.f89e7e0b.woff2) format("woff2"),
        url(/static/media/Inter-UI-Regular.363747e1.woff) format("woff");
}

/* @font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 400;
    src: url("assets/fonts/Inter-UI-Regular.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Regular.woff") format("woff");
}
 */

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    src: local("Inter UI Regular"),
        url(/static/media/Inter-UI-Italic.3b021f6e.woff2) format("woff2"),
        url(/static/media/Inter-UI-Italic.55f5dce5.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI Medium";
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: local("Inter UI Medium"),
        url(/static/media/Inter-UI-Medium.77859c1d.woff2) format("woff2"),
        url(/static/media/Inter-UI-Medium.31d669fe.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 500;
    font-display: auto;
    src: local("Inter UI Medium"),
        url(/static/media/Inter-UI-MediumItalic.c1e5e7dc.woff2) format("woff2"),
        url(/static/media/Inter-UI-MediumItalic.f6ef784d.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: local("Inter UI"),
        url(/static/media/Inter-UI-SemiBold.f6d88f03.woff2) format("woff2"),
        url(/static/media/Inter-UI-SemiBold.36539b99.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 600;
    font-display: auto;
    src: local("Inter UI"),
        url(/static/media/Inter-UI-SemiBoldItalic.ffbcabff.woff2) format("woff2"),
        url(/static/media/Inter-UI-SemiBoldItalic.cfedc039.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: local("Inter UI"),
        url(/static/media/Inter-UI-Bold.65217dd1.woff2) format("woff2"),
        url(/static/media/Inter-UI-Bold.9efd065e.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    src: local("Inter UI"),
        url(/static/media/Inter-UI-BoldItalic.80eea088.woff2) format("woff2"),
        url(/static/media/Inter-UI-BoldItalic.ffda4344.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 800;
    font-display: auto;
    src: url(/static/media/Inter-UI-ExtraBold.d363bed9.woff2) format("woff2"),
        url(/static/media/Inter-UI-ExtraBold.fa12540b.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 800;
    font-display: auto;
    src: url(/static/media/Inter-UI-ExtraBoldItalic.eda30f30.woff2) format("woff2"),
        url(/static/media/Inter-UI-ExtraBoldItalic.c90cfe6d.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(/static/media/Inter-UI-Black.70fc74d4.woff2) format("woff2"),
        url(/static/media/Inter-UI-Black.49411ae4.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 900;
    font-display: auto;
    src: url(/static/media/Inter-UI-BlackItalic.53f7361c.woff2) format("woff2"),
        url(/static/media/Inter-UI-BlackItalic.b21eef04.woff) format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "SF UI Text Regular";
    font-display: auto;
    src: url(/static/media/SFUIText-Regular.df14d76c.woff) format("woff");
}
/* --------------------------------------------------------------------------
Single variable font.

Note that you may want to do something like this to make sure you're serving
constant fonts to older browsers:
html {
  font-family: 'Inter UI', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var', sans-serif;
  }
}

BUGS:
- Safari 12.0 will default to italic instead of regular when font-weight
  is provided in a @font-face declaration.
  Workaround: Use "Inter UI var alt" for Safari, or explicitly set
  `font-variation-settings:"slnt" DEGREE`.
*/
@font-face {
    font-display: fallback;
    font-family: "Inter UI var";
    font-weight: 400 900;
    font-style: oblique 0deg 10deg;
    src: url(/static/media/Inter-UI.var.9c23fb2e.woff2) format("woff2-variations"),
        url(/static/media/Inter-UI.var.9c23fb2e.woff2) format("woff2");
}

/* --------------------------------------------------------------------------

"Inter UI var alt" is recommended for Safari and Edge, for reliable italics.

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var alt', sans-serif;
  }
}

*/
@font-face {
    font-display: fallback;
    font-family: "Inter UI var alt";
    font-weight: 400 900;
    font-style: normal;
    font-named-instance: "Regular";
    src: url(/static/media/Inter-UI-upright.var.40eeb1b7.woff2)
            format("woff2 supports variations(gvar)"),
        url(/static/media/Inter-UI-upright.var.40eeb1b7.woff2)
            format("woff2-variations"),
        url(/static/media/Inter-UI-upright.var.40eeb1b7.woff2) format("woff2");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI var alt";
    font-weight: 400 900;
    font-style: italic;
    font-named-instance: "Italic";
    src: url(/static/media/Inter-UI-italic.var.0d54801e.woff2)
            format("woff2 supports variations(gvar)"),
        url(/static/media/Inter-UI-italic.var.0d54801e.woff2) format("woff2-variations"),
        url(/static/media/Inter-UI-italic.var.0d54801e.woff2) format("woff2");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI Semi Bold";
    src: url(/static/media/Inter-UI-SemiBold.36539b99.woff)
            format("woff2 supports variations(gvar)"),
        url(/static/media/Inter-UI-SemiBold.f6d88f03.woff2) format("woff2-variations"),
        url(/static/media/Inter-UI-SemiBold.f6d88f03.woff2) format("woff2");
}

html,
body {
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
}

body {
    height: 100vh;
    width: 100vw;
    transition: all 0.5s ease;
    margin: 0;
    padding: 0;
    font-family: "  ", -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif, "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

input {
    font-family: "Inter UI Regular", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}
textarea {
    font-family: "Inter UI Regular", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    margin: 0px;
    padding: 0px;
}

br {
    margin: 10px;
}

ul.slick-dots {
    position: relative;
    margin-top: -0.5rem;
    margin-bottom: 2rem;
}
.slider-class {
    background-color: white;
}

/* .slick-next {
    display: none !important;
}  */

/* .slick-prev:before,
.slick-next:before {
  color: yellow;
}
 */
.blurred {
    -webkit-filter: blur(5px);
            filter: blur(5px);
    pointer-events: none;
}
.ModalOpen {
    -webkit-animation: openModal 0.4s ease-out forwards;
            animation: openModal 0.4s ease-out forwards;
}
.ModalClosed {
    -webkit-animation: closeModal 0.4s ease-out forwards;
            animation: closeModal 0.4s ease-out forwards;
}

.square-option {
    height: 100px;
}

.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.capitalize {
    text-transform: capitalize;
}

.descriptions {
    word-wrap: break-word;
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -hp-pre-wrap;
}
@-webkit-keyframes openModal {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes openModal {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes closeModal {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(130%);
                transform: translateY(130%);
    }
}

@keyframes closeModal {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(130%);
                transform: translateY(130%);
    }
}

.in {
    -webkit-animation: fadeIn 500ms;
            animation: fadeIn 500ms;
}

.out {
    -webkit-animation: fadeOut 500ms;
            animation: fadeOut 500ms;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        left: -200px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        left: -200px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        left: 0px;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        left: 0px;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.valid::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cae6d4;
    opacity: 1;
    /* Firefox */
}

.valid::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cae6d4;
    opacity: 1;
    /* Firefox */
}

.invalid::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bd4c49;
    opacity: 1;
    /* Firefox */
}

.invalid::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bd4c49;
    opacity: 1;
    /* Firefox */
}

.invalid:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bd4c49;
}

.invalid::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bd4c49;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block !important;
}

.slick-dots li {
    margin: 0px;
}

.slick-dots li.slick-active button:before {
    color: #319be2;
    font-size: 10px;
    padding-top: 0px;
}

.slick-dots li.slick-active {
    padding-left: 2px;
}

.slick-dots li button:before {
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
    .zsiq_floatmain {
        bottom: 90px !important;
        right: 20px !important;
        display: none!important;
    }
}

.dont-break-out {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
      
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
      
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -webkit-hyphens: auto;
        hyphens: auto;
      
}

/* width */
.scrollbar::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 6px; */
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #929292;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.hide-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.link {
    cursor: pointer;
    text-decoration: underline;
    color: green;
}

.closedReferralTooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: black;
    grid-gap: 1em;
    gap: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 20px;
    font-size: 10px;
    width: 250px;
}

.stayingTooltip {
    pointer-events: auto !important;
}

.stayingTooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

@media only screen and (min-width: 481px) {
    .form-tooltip {
        box-shadow: 16px 16px 40px rgba(0, 0, 0, 0.2);
        white-space: break-spaces;
        z-index: 999999!important;
        max-width: 600px;
        padding: 24px!important;
        font-size: 14px!important;
        line-height: 24px;
        font-weight: 500;
        font-style: normal;
        color: #333!important;
        pointer-events: auto !important;
        /* margin: 0 auto; */
    }
}
@media only screen and (max-width: 480px) {
    .form-tooltip {
        box-shadow: 16px 16px 40px rgba(0, 0, 0, 0.2);
        white-space: break-spaces;
        z-index: 999999!important;
        max-width: 398px;
        padding: 24px!important;
        font-size: 12px!important;
        line-height: 24px;
        font-weight: 500;
        font-style: normal;
        color: #333!important;
        margin: 0 32px;
        position: relative;
        min-width: 100vw;
        margin: 0 auto;
       overflow: scroll;
       pointer-events: auto !important;
        /* margin: 0 auto; */
    }
}



/* .form-tooltip ol li, .form-tooltip ul li {
    margin-top: 1rem;
} */
.form-tooltip ol, .form-tooltip ul {
    margin: 0;
}

.form-tooltip.show {
    opacity: 1!important;
}

.navbar-dropdown path {
    transition: all .3s ease-in-out;
}

#cloudsponge-root-holder #cloudsponge-address-book{
    height: 70%;
    z-index: 99999;
    background-color: transparent;
}

#cloudsponge-root-holder #cloudsponge-address-book .cloudsponge-topbar {
    background-color: #1FC07F;
}

#cloudsponge-root-holder #cloudsponge-overlay {
    background-color: rgba(0,0,0,0.2);
    z-index: 99998;
}

#cloudsponge-root-holder #cloudsponge-address-book .cloudsponge-header {
    background-color: #1FC07F;
}

@media only screen and (max-width: 768px) {
    #cloudsponge-root-holder #cloudsponge-address-book{
        height: 100%;
    }
}

@-webkit-keyframes fadeInForReactJoyride {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInForReactJoyride {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.react-joyride__spotlight {
    -webkit-animation: fadeInForReactJoyride .3s;
            animation: fadeInForReactJoyride .3s;
}

/* Override badge background color to match NuOp's palette */
:root {
    --rnf-unseen-badge-bg-color: #1fc07f;
}
/* TODO: remove if Support Widget disappear */
/* Override bottom-right placement for toast */
.Toastify__toast-container--bottom-right {
    bottom: 8em;
    right: 1em;
}
