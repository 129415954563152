.LoaderContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
  .LoaderContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .LogoContainer {
    height: 150px;
    width: 150px;
    
  }
  .loaderLogo {
    max-width: 100%;
  }
  
  .loaderLogo2 {
    max-width: 100%;
  }
  
  .nuOpLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }
  .nuOpLoader:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid  #00A15F;
    border-color: #00A15F transparent #00A15F transparent;
    animation: nuOpLoader .8s linear infinite;
  }
  @keyframes nuOpLoader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Loader2 styles  */
  
  .Logo2Container {
    height: 350px;
    width: 350px;
  }
  
  .nuOpLoader2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 230px;
    
  }
  .nuOpLoader2:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid  #00A15F;
    border-color: #00A15F transparent #00A15F transparent;
    animation: nuOpLoader .4s linear infinite;
  }
  @keyframes nuOpLoader2 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  


  
  
  