@font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: url("./assets/fonts/Roboto-Medium.ttf"); /* IE9 Compat Modes */
}

/* montserrat-regular - latin */
@font-face {
    font-display: fallback;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("./assets/fonts/montserrat/montserrat-v14-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Montserrat Regular"),
        /* local('Montserrat-Regular'), */
            url("./assets/fonts/montserrat/montserrat-v14-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./assets/fonts/montserrat/montserrat-v14-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./assets/fonts/montserrat/montserrat-v14-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("./assets/fonts/montserrat/montserrat-v14-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./assets/fonts/montserrat/montserrat-v14-latin-regular.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}

@font-face {
    font-display: fallback;
    font-family: "Montserrat Bold";
    font-style: bold;
    font-weight: bold;
    font-display: auto;
    src: url("./assets/fonts/montserrat/Montserrat-Bold.otf"); /* IE9 Compat Modes */
    src: local("Montserrat Bold"); /* local('Montserrat-Regular'), */
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI Regular";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: local("Inter UI Regular"),
        url("assets/fonts/Inter-UI-Regular.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Regular.woff") format("woff");
}

/* @font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 400;
    src: url("assets/fonts/Inter-UI-Regular.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Regular.woff") format("woff");
}
 */

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    src: local("Inter UI Regular"),
        url("assets/fonts/Inter-UI-Italic.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Italic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI Medium";
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: local("Inter UI Medium"),
        url("assets/fonts/Inter-UI-Medium.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Medium.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 500;
    font-display: auto;
    src: local("Inter UI Medium"),
        url("assets/fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: local("Inter UI"),
        url("assets/fonts/Inter-UI-SemiBold.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-SemiBold.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 600;
    font-display: auto;
    src: local("Inter UI"),
        url("assets/fonts/Inter-UI-SemiBoldItalic.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-SemiBoldItalic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: local("Inter UI"),
        url("assets/fonts/Inter-UI-Bold.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Bold.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    src: local("Inter UI"),
        url("assets/fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 800;
    font-display: auto;
    src: url("assets/fonts/Inter-UI-ExtraBold.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-ExtraBold.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 800;
    font-display: auto;
    src: url("assets/fonts/Inter-UI-ExtraBoldItalic.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-ExtraBoldItalic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("assets/fonts/Inter-UI-Black.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-Black.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI";
    font-style: italic;
    font-weight: 900;
    font-display: auto;
    src: url("assets/fonts/Inter-UI-BlackItalic.woff2") format("woff2"),
        url("assets/fonts/Inter-UI-BlackItalic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "SF UI Text Regular";
    font-display: auto;
    src: url("assets/fonts/sf-ui-font/SFUIText-Regular.woff") format("woff");
}
/* --------------------------------------------------------------------------
Single variable font.

Note that you may want to do something like this to make sure you're serving
constant fonts to older browsers:
html {
  font-family: 'Inter UI', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var', sans-serif;
  }
}

BUGS:
- Safari 12.0 will default to italic instead of regular when font-weight
  is provided in a @font-face declaration.
  Workaround: Use "Inter UI var alt" for Safari, or explicitly set
  `font-variation-settings:"slnt" DEGREE`.
*/
@font-face {
    font-display: fallback;
    font-family: "Inter UI var";
    font-weight: 400 900;
    font-style: oblique 0deg 10deg;
    src: url("assets/fonts/Inter-UI.var.woff2") format("woff2-variations"),
        url("assets/fonts/Inter-UI.var.woff2") format("woff2");
}

/* --------------------------------------------------------------------------

"Inter UI var alt" is recommended for Safari and Edge, for reliable italics.

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter UI var alt', sans-serif;
  }
}

*/
@font-face {
    font-display: fallback;
    font-family: "Inter UI var alt";
    font-weight: 400 900;
    font-style: normal;
    font-named-instance: "Regular";
    src: url("assets/fonts/Inter-UI-upright.var.woff2")
            format("woff2 supports variations(gvar)"),
        url("assets/fonts/Inter-UI-upright.var.woff2")
            format("woff2-variations"),
        url("assets/fonts/Inter-UI-upright.var.woff2") format("woff2");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI var alt";
    font-weight: 400 900;
    font-style: italic;
    font-named-instance: "Italic";
    src: url("assets/fonts/Inter-UI-italic.var.woff2")
            format("woff2 supports variations(gvar)"),
        url("assets/fonts/Inter-UI-italic.var.woff2") format("woff2-variations"),
        url("assets/fonts/Inter-UI-italic.var.woff2") format("woff2");
}

@font-face {
    font-display: fallback;
    font-family: "Inter UI Semi Bold";
    src: url("assets/fonts/Inter-UI-SemiBold.woff")
            format("woff2 supports variations(gvar)"),
        url("assets/fonts/Inter-UI-SemiBold.woff2") format("woff2-variations"),
        url("assets/fonts/Inter-UI-SemiBold.woff2") format("woff2");
}

html,
body {
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
}

body {
    height: 100vh;
    width: 100vw;
    transition: all 0.5s ease;
    margin: 0;
    padding: 0;
    font-family: "  ", -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif, "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

input {
    font-family: "Inter UI Regular", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}
textarea {
    font-family: "Inter UI Regular", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    margin: 0px;
    padding: 0px;
}

br {
    margin: 10px;
}

ul.slick-dots {
    position: relative;
    margin-top: -0.5rem;
    margin-bottom: 2rem;
}
.slider-class {
    background-color: white;
}

/* .slick-next {
    display: none !important;
}  */

/* .slick-prev:before,
.slick-next:before {
  color: yellow;
}
 */
.blurred {
    filter: blur(5px);
    pointer-events: none;
}
.ModalOpen {
    animation: openModal 0.4s ease-out forwards;
}
.ModalClosed {
    animation: closeModal 0.4s ease-out forwards;
}

.square-option {
    height: 100px;
}

.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.capitalize {
    text-transform: capitalize;
}

.descriptions {
    word-wrap: break-word;
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -hp-pre-wrap;
}
@keyframes openModal {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(130%);
    }
}

.in {
    animation: fadeIn 500ms;
}

.out {
    animation: fadeOut 500ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        left: -200px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        left: 0px;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.valid::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cae6d4;
    opacity: 1;
    /* Firefox */
}

.valid:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cae6d4;
}

.valid::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #cae6d4;
}

.invalid::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bd4c49;
    opacity: 1;
    /* Firefox */
}

.invalid:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bd4c49;
}

.invalid::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bd4c49;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block !important;
}

.slick-dots li {
    margin: 0px;
}

.slick-dots li.slick-active button:before {
    color: #319be2;
    font-size: 10px;
    padding-top: 0px;
}

.slick-dots li.slick-active {
    padding-left: 2px;
}

.slick-dots li button:before {
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
    .zsiq_floatmain {
        bottom: 90px !important;
        right: 20px !important;
        display: none!important;
    }
}

.dont-break-out {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
      
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
      
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      
}

/* width */
.scrollbar::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 6px; */
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px;
    transition: all .3s ease-in-out;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #929292;
    transition: all .3s ease-in-out;
}

.hide-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.link {
    cursor: pointer;
    text-decoration: underline;
    color: green;
}

.closedReferralTooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: black;
    gap: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 20px;
    font-size: 10px;
    width: 250px;
}

.stayingTooltip {
    pointer-events: auto !important;
}

.stayingTooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

@media only screen and (min-width: 481px) {
    .form-tooltip {
        box-shadow: 16px 16px 40px rgba(0, 0, 0, 0.2);
        white-space: break-spaces;
        z-index: 999999!important;
        max-width: 600px;
        padding: 24px!important;
        font-size: 14px!important;
        line-height: 24px;
        font-weight: 500;
        font-style: normal;
        color: #333!important;
        pointer-events: auto !important;
        /* margin: 0 auto; */
    }
}
@media only screen and (max-width: 480px) {
    .form-tooltip {
        box-shadow: 16px 16px 40px rgba(0, 0, 0, 0.2);
        white-space: break-spaces;
        z-index: 999999!important;
        max-width: 398px;
        padding: 24px!important;
        font-size: 12px!important;
        line-height: 24px;
        font-weight: 500;
        font-style: normal;
        color: #333!important;
        margin: 0 32px;
        position: relative;
        min-width: 100vw;
        margin: 0 auto;
       overflow: scroll;
       pointer-events: auto !important;
        /* margin: 0 auto; */
    }
}



/* .form-tooltip ol li, .form-tooltip ul li {
    margin-top: 1rem;
} */
.form-tooltip ol, .form-tooltip ul {
    margin: 0;
}

.form-tooltip.show {
    opacity: 1!important;
}

.navbar-dropdown path {
    transition: all .3s ease-in-out;
}

#cloudsponge-root-holder #cloudsponge-address-book{
    height: 70%;
    z-index: 99999;
    background-color: transparent;
}

#cloudsponge-root-holder #cloudsponge-address-book .cloudsponge-topbar {
    background-color: #1FC07F;
}

#cloudsponge-root-holder #cloudsponge-overlay {
    background-color: rgba(0,0,0,0.2);
    z-index: 99998;
}

#cloudsponge-root-holder #cloudsponge-address-book .cloudsponge-header {
    background-color: #1FC07F;
}

@media only screen and (max-width: 768px) {
    #cloudsponge-root-holder #cloudsponge-address-book{
        height: 100%;
    }
}

@keyframes fadeInForReactJoyride {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.react-joyride__spotlight {
    animation: fadeInForReactJoyride .3s;
}

/* Override badge background color to match NuOp's palette */
:root {
    --rnf-unseen-badge-bg-color: #1fc07f;
}
/* TODO: remove if Support Widget disappear */
/* Override bottom-right placement for toast */
.Toastify__toast-container--bottom-right {
    bottom: 8em;
    right: 1em;
}